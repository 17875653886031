

























































































































































import Vue from 'vue';
import APISTATE from '@/enums/APISTATE';
import { mapActions, mapGetters } from 'vuex';
import { NumberPerStudentTypeReportedDto } from '@/models/planning/NumberPerStudentTypeReportedDto';
import { SchoolTermDto } from '@/models/planning/SchoolTermDto';


export default Vue.extend({
  name: 'number-pr-students',
  data: () => ({
    filterDepartments: undefined,
    filterSchoolTerms: undefined,
    filterSchoolSubjects: undefined,
    skipped: 0,
    skip: 0,
    taken: 0,
    take: undefined,
    total: 0
  }),
  beforeMount() {
    this.resetFilters();
  },
  async mounted() {
    await Promise.all([
      this.loadDepartments(),
      this.loadSchoolTerms(),
      this.loadSchoolSubjects()
    ]);
    this.applyGlobalSchoolTermSelection();
    if (this.filterSchoolTerms) {
      await this.getClassSubjects();
    }
  },
  methods: {
    ...mapActions('departments', ['loadDepartments']),
    ...mapActions('schoolTermStore', ['loadSchoolTerms']),
    ...mapActions('schoolSubjectsStore', ['loadSchoolSubjects']),
    ...mapActions('classSubjectStore', ['loadClassSubjects', 'setFilterProperty', 'clearFilterProperty', 'resetFilters']),
    ...mapActions('classSubjectStudentTypeStore', ['loadFiltered', 'update']),
    ...mapActions(['updatePageTitle', 'updateGlobalSchoolTermSelection']),
    clearSkipTake() {
      this.skipped = 0;
      this.taken = 0;
    },
    async onBlur(numberPerStudentTypeReportedDto: NumberPerStudentTypeReportedDto) {
      await this.update(numberPerStudentTypeReportedDto);
    },
    async getClassSubjects() {
      if (this.taken === this.totalRecords)
        return; // Nothing more to do

      const result = await this.loadFiltered(
        {
          departments: this.filterDepartments?.map(f => f.id),
          schoolTerms: this.filterSchoolTerms?.id,
          schoolSubjects: this.filterSchoolSubjects?.map(f => f.id),
          skip: this.taken, 
          take: this.take, 
          total: this.total
        }
      ); 

      if (!result) return;

      this.skipped = result.skipped;
      this.taken = result.taken;
      this.totalRecords = result.totalRecords;
    },
    applyGlobalSchoolTermSelection() {
      const globalSchoolTerm = this.schoolTerms.find((q:SchoolTermDto) => q.id === this.globalSchoolTermSelection);
      if (globalSchoolTerm) {
        this.filterSchoolTerms = globalSchoolTerm;
      }
    },

  },
  computed: {
    ...mapGetters(['globalSchoolTermSelection']),
    ...mapGetters('departments', {departments: 'departments', isLoadingDepartments: 'isLoading'}),
    ...mapGetters('schoolTermStore', ['schoolTerms', 'schoolTermApiState']),
    ...mapGetters('schoolSubjectsStore', {schoolSubjects: 'schoolSubjects', isLoadingSchoolSubjects: 'isLoading'}),
    ...mapGetters('classSubjectStore', ['classSubjects', 'classSubjectApiState']),
    ...mapGetters('classSubjectStudentTypeStore', {numberPerStudentTypeReporteds: 'numberPerStudentTypeReporteds', isLoadingNumberPrStudenTypes: 'isLoading'}),
    isLoading(): Boolean {
      return this.classSubjectApiState === APISTATE.LOADING;
    },
    isLoadingSchoolTerms(): Boolean {
      return this.schoolTermApiState === APISTATE.LOADING;
    }

  }

})
